<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="../../assets/image/icon/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/admin-config')"
          >
          <span class="robot-setting-title">&nbsp;&nbsp;&nbsp;구동제어 변수교정</span>
          <v-spacer />
        </v-row>
        <v-col class="content-area">
          <h3 class="robot-option-title">conveyor_data</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="loadData('conveyor_data')" > 갱신 </v-btn> </p>
            <p class="default-robot-option-text"> : </p>
          </v-col>
          <v-col v-for="(value, name) in conveyor_data"
                  :key="name"
                  class="12 pa-0"
          >
            <p class="default-robot-option-text"> {{name}} </p>
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> {{value}} </p>
          </v-col>


          <h3 class="robot-option-title">conveyor_parameter</h3>
          <v-col class="12 pa-0">
            <input v-model="conveyor_parameter_name" placeholder="num" type="text" class="input-setting">
            <input v-model="conveyor_parameter_value" placeholder="value" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('conveyor_parameter')" > 저장 </v-btn> </p>
          </v-col>
          <v-col v-for="(value, name) in conveyor_parameter"
                 :key="name"
                 class="12 pa-0"
          >
            <p class="default-robot-option-text"> {{name}} </p>
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> {{value}} </p>
          </v-col>



          <h3 class="robot-option-title">power_data</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="loadData('power_data')" > 갱신 </v-btn> </p>
            <p class="default-robot-option-text"> : </p>
          </v-col>
          <v-col v-for="(value, name) in power_data"
                 :key="name"
                 class="12 pa-0"
          >
            <p class="default-robot-option-text"> {{name}} </p>
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> {{value}} </p>
          </v-col>


          <h3 class="robot-option-title">power_parameter</h3>
          <v-col class="12 pa-0">
            <input v-model="power_parameter_name" placeholder="num" type="text" class="input-setting">
            <input v-model="power_parameter_value" placeholder="value" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('power_parameter')" > 저장 </v-btn> </p>
          </v-col>
          <v-col v-for="(value, name) in power_parameter"
                 :key="name"
                 class="12 pa-0"
          >
            <p class="default-robot-option-text"> {{name}} </p>
            <p class="default-robot-option-text"> : </p>
            <p class="default-robot-option-text"> {{value}} </p>
          </v-col>


          <h3 class="robot-option-title">param_data</h3>
          <v-col v-for="(value, index) in param_data"
                 :key="index"
                 class="12 pa-0"
          >
            <p class="default-robot-option-text"> {{index}} </p>
            <input v-model="param_data[index]" :placeholder="value" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('param_data')" > 저장 </v-btn> </p>
          </v-col>


          <h3 class="robot-option-title">위치제어</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 이동거리 </p>
            <input v-model="linear" placeholder="10000 = 1m 전진" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('linear')" > 이동 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 회전각도 </p>
            <input v-model="angular" placeholder="3600 = 360도 우회전" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('angular')" > 회전 </v-btn> </p>
          </v-col>
          <v-btn
            class="btn mt-9"
            color="#00BFB4"
            dark
            @click="saveData('mode1')"
          >
            위치제어모드
          </v-btn>
          <v-btn
            class="btn mt-9"
            color="#00BFB4"
            dark
            @click="saveData('mode2')"
          >
            속도제어모드
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'AdminCalibration',
  data() {
    return {
      conveyor_data: {},
      conveyor_data_read_cmd: `{"serviceName": "/@robotNamespace/Conveyor_data_read_cmd", "serviceType": "tetraDS_interface/conveyor_data_read", "options": {"num": 0}}`,

      conveyor_parameter: {},
      conveyor_parameter_name: '',
      conveyor_parameter_value: '',
      conveyor_parameter_read_cmd: `{"serviceName": "/@robotNamespace/Conveyor_parameter_read_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_read", "options": {"num": 0}}`,
      conveyor_parameter_write_cmd: `{"serviceName": "/@robotNamespace/Conveyor_parameter_write_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_write", "options": {"num": @name, "data": @value}}`,

      power_data: {},
      power_data_read_cmd: `{"serviceName": "/@robotNamespace/Power_data_read_cmd", "serviceType": "tetraDS_interface/power_data_read", "options": {"num": 0}}`,

      power_parameter: {},
      power_parameter_name: '',
      power_parameter_value: '',
      power_parameter_read_cmd: `{"serviceName": "/@robotNamespace/Power_parameter_read_cmd", "serviceType": "tetraDS_interface/power_parameter_read", "options": {"num": 0}}`,
      power_parameter_write_cmd: `{"serviceName": "/@robotNamespace/Power_parameter_write_cmd", "serviceType": "tetraDS_interface/power_parameter_write", "options": {"num": @name, "data": @value}}`,

      param_data: [0,0,0,0,0,0,0,0,0,0],
      param_read_cmd: `{"serviceName": "/@robotNamespace/param_read_cmd", "serviceType": "tetraDS/parameter_read", "options": {"num": @value}}`,
      param_write_cmd: `{"serviceName": "/@robotNamespace/param_write_cmd", "serviceType": "tetraDS/parameter_write", "options": {"num": @name, "data": @value}}`,

      linear: 10000,
      angular: 3600,
      linear_cmd: `{"serviceName": "/@robotNamespace/linear_move_cmd", "serviceType": "tetraDS/linear_position_move", "options": {"linear_position": @value}}`,
      angular_cmd: `{"serviceName": "/@robotNamespace/angular_move_cmd", "serviceType": "tetraDS/angular_position_move", "options": {"angular_degree": @value}}`,
      mode1: `{"serviceName": "/@robotNamespace/mode_change_cmd", "serviceType": "tetraDS/set_move_mode", "options": {"mode": 1}}`,
      mode2: `{"serviceName": "/@robotNamespace/mode_change_cmd", "serviceType": "tetraDS/set_move_mode", "options": {"mode": 0}}`,
    }
  },
  created() {
    this.loadData('conveyor_data');
    this.loadData('conveyor_parameter');
    this.loadData('power_data');
    this.loadData('power_parameter');
    this.loadData('param_data');
  },
  computed: {
    robotNamespace(){
      return this.$store.state.robot_namespace;
    },
  },
  methods: {
    loadData: async function(name){
      let option= '';
      let res= '';
      let prom= [];
      let responseArray = [];
      switch (name){
        case'conveyor_data':
          option= this.conveyor_data_read_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          this.conveyor_data = res.data;
          break;
        case'conveyor_parameter':
          option= this.conveyor_parameter_read_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          this.conveyor_parameter = res.data;
          break;
        case'power_data':
          option= this.power_data_read_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          this.power_data = res.data;
          break;
        case'power_parameter':
          option= this.power_parameter_read_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          this.power_parameter = res.data;
          break;
        case'param_data':
          prom = this.param_data.map((item, index)=>{
            option= this.param_read_cmd.toString();
            option= option.replace("@value", index.toString());
            option= option.replace('@robotNamespace', this.robotNamespace);
            return this.$axios.post('/ros/service', JSON.parse(option))
              .then((response)=> response.data.data);
          });
          responseArray = await Promise.all(prom);
          for(let i=0; i<responseArray.length; i++){
            this.param_data[i]= responseArray[i];
          }
          break;
      }
    },
    saveData: async function(name){
      let option= '';
      let res= '';
      switch (name){
        case'conveyor_parameter':
          option= this.conveyor_parameter_write_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          option= option.replace("@value", this.conveyor_parameter_value);
          option= option.replace("@name", this.conveyor_parameter_name);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          break;
        case'power_parameter':
          option= this.power_parameter_write_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          option= option.replace("@value", this.power_parameter_value);
          option= option.replace("@name", this.power_parameter_name);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          if (res.status !== 200) return;
          break;
        case'param_data':
          for(let i=0; i<this.param_data.length; i++){
            option= this.param_write_cmd.toString();
            option= option.replace('@robotNamespace', this.robotNamespace);
            option= option.replace("@value", this.param_data[i].toString());
            option= option.replace("@name", i.toString());
            res= await this.$axios.post('/ros/service', JSON.parse(option));
            if (res.status !== 200) return;
          }
          break;
        case'linear':
          option= this.linear_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          option= option.replace("@value", this.linear.toString());
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          return;
        case'angular':
          option= this.angular_cmd.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          option= option.replace("@value", this.angular.toString());
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          return;
        case'mode1':
          option= this.mode1.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          return;
        case'mode2':
          option= this.mode2.toString();
          option= option.replace('@robotNamespace', this.robotNamespace);
          res= await this.$axios.post('/ros/service', JSON.parse(option));
          return;
      }
      if (res&& res.status === 200) await this.loadData(name);
    },
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.robot-setting-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 60vh;
  text-align: center;
  overflow: auto;
}

.robot-option-title {
  padding-top: 30px;
  font-size: 2rem;
  color: white;
}

.input-setting {
  width: 30%;
  height: 80px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-setting:focus {
  outline: #9aa0ae solid;
}

.textarea {
  width: 70%;
  height: 180px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 1rem;
  color: white;
}

.textarea:focus {
  outline: #9aa0ae solid;
}

.default-robot-option-text {
  padding-left: 30px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 30%;
  font-size: 1.5rem;
  color: white;
}

.default-robot-option-text > span {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .robot-setting-title {
    font-size: 20px !important;
  }

  .input-setting {
    width: 30%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .textarea {
    width: 70%;
    height: 120px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .default-robot-option-text {
    padding-left: 30px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 30%;
    font-size: 1.0rem;
    color: white;
  }

  .default-robot-option-text > span {
    font-size: 0.7rem;
  }
}
</style>
